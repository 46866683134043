import { z } from "zod";

import { eventTypeAppCardZod } from "../eventTypeAppCardZod";

export const appDataSchema = eventTypeAppCardZod.merge(
  z.object({
    // FIXME: Due to some reason autogenerated zod schema at prisma/zod/eventtype.ts fails typecheck on using `.default()`. It somehow thinks that trackingId can be undefined
    // z.lazy has something to do with it. Figure it out later.
    trackingId: z.string().default("") as unknown as z.ZodString,
  })
);

export const appKeysSchema = z.object({});
